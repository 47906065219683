const APP = {
    NAME: 'NEARHub',

    DEFAULT_ROYALTY: 5,
    MIN_CREATOR_ROYALTY: 0,
    MIN_COLLABORATOR_ROYALTY: 0.01,
    MAX_ROYALTY: 90,

    MIN_NEARS: 0,
    MIN_NEARS_TO_MINT: 0.05,

    NEARS_TO_NEXT_BID: '1',

    MAX_COLLABORATORS: 6,

    GEM_DESCRIPTION_MAX_LENGTH: 160,
    GEM_TITLE_MAX_LENGTH: 100,
    GEM_MAX_SIZE_MB: 10,

    AN_MEDIA_MAX_SIZE_BEFORE_THUMBNAIL_MB: 1,

    MAX_ITEMS_PER_PAGE_PROFILE: 18,
    MAX_ITEMS_PER_PAGE_HOME: 18,

    PREPAID_GAS_LIMIT: '200000000000000',
    PREPAID_GAS_LIMIT_HALF: '100000000000000',
    DEPOSIT_DEFAULT: '15000000000000000000000',

    HASH_SOURCE: 'pinata',
    PINATA_API_KEY: 'a69c9db4958c7f0b7524',
    PINATA_API_SECRET: '25bb2a66f85f33f2e9066176d64511b8ce531c9e6acd6c5857ad24048679c25a',

    USE_STORAGE_FEES: false,
};

export default APP;